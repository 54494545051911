.section-logo {
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: center;
    .form-container {
        width: 30%;
        padding: 30px;
        border-radius: 30px;
        box-shadow: 0 0 12px #494949;
    }
}